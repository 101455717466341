import { defineStore } from 'pinia'
import { $fetchApi } from '~/composables/useFetchApi'
import { useUserStore } from '~/stores/user'

export const setSubEndDate = (sub) => {
  return {
    ...sub,
    endDate: sub.offboardingDate || sub.endDate,
    originalEndDate: sub.endDate
  }
}

export const getInitialState = () => ({
  currentSubscription: null,
  pastSubscriptions: {},
  futureSubscriptions: [],
  subscriptions: [],
  newsletterOptions: [],
  paymentProfile: null,
  canPauseSubscription: false,
  remainingPause: 1,
  paymentSession: null,
  validatingPayment: false
})

export const useAccountStore = defineStore('account', {
  state: () => getInitialState(),
  actions: {
    async signUp({ email, firstName, lastName, password }) {
      const body = {
        email,
        firstName,
        lastName,
        password,
        country: 'nl'
      }

      await $fetchApi('v3/member/signup', {
        method: 'POST',
        body,
        headers: { 'X-Device': 'Website' }
      })
    },
    async recoverPassword(email) {
      await $fetchApi('/member/forgotpassword', {
        method: 'POST',
        body: { email }
      })
    },
    async resetPassword({ email, token, newPassword }) {
      const body = {
        email,
        token,
        newPassword
      }

      await $fetchApi('/member/resetpassword', {
        method: 'POST',
        body
      })
    },

    async updateProfile(profile) {
      const response = await $fetchApi('/v4/member/profile', {
        method: 'PUT',
        body: profile
      })

      const userStore = useUserStore()
      userStore.setUser(response)
    },
    async updateProfilePhoto(photo) {
      const formData = new FormData()
      formData.append('photo', photo)

      const response = await $fetchApi('/member/photo', {
        method: 'POST',
        body: formData
      })

      const userStore = useUserStore()
      userStore.setUserPhoto(response.photo)
    },
    async updateNewsletterOptions(options) {
      const response = await $fetchApi('/nbi/member/newsletteroptions', {
        method: 'PUT',
        body: options
      })

      this.newsletterOptions = response
    },
    async getPaymentProfile(userUid) {
      const response = await $fetchApi(`/user/${userUid}/defaultPaymentProfile`)

      this.paymentProfile = response || null
    },
    async getNewsletterOptions() {
      const response = await $fetchApi('/nbi/member/newsletteroptions')

      this.newsletterOptions = response

      return response
    },
    async getCurrentSubscription() {
      const response = await $fetchApi(
        '/usersubscriptions/valid/subscriptions?period=current'
      )

      const currentSubscription = response.items[0]

      if (currentSubscription) {
        this.currentSubscription = setSubEndDate(currentSubscription)
      }
    },
    async getPastSubscriptions(page) {
      const response = await $fetchApi(
        `/usersubscriptions/valid/subscriptions?period=historical&PageSize=6&PageIndex=${page}`
      )

      this.pastSubscriptions = {
        ...response,
        items: response.items.map((sub) => setSubEndDate(sub))
      }
    },
    async getFutureSubscriptions() {
      const response = await $fetchApi(
        '/usersubscriptions/valid/subscriptions?period=future'
      )

      this.futureSubscriptions = response.items
        .map((sub) => setSubEndDate(sub))
        .reverse()
    },
    async getPauseInformation(userId) {
      const response = await $fetchApi(
        `users/${userId}/autorenewal/membershipPauseHistories/RemainingPause`
      )

      this.canPauseSubscription = response.isValidUserToBePaused
      this.remainingPause = response.remainingPause
    },
    async pauseSubscription({ userId, months }) {
      await $fetchApi(
        `orders/users/${userId}/autorenewal/membershipPauseHistories`,
        { method: 'PUT', body: { userId, operation: 'pause', months } }
      )

      const userStore = useUserStore()

      await Promise.all([
        this.getCurrentSubscription(),
        userStore.updateUserData(),
        this.getPauseInformation(userId)
      ])
    },
    async cancelSubscription({ userId, cancelEndOfTerm }) {
      await $fetchApi(`/v3/user/userSubscription/cancel`, {
        method: 'PUT',
        body: { cancelEndOfTerm }
      })

      const userStore = useUserStore()
      await Promise.all([
        this.getCurrentSubscription(),
        this.getFutureSubscriptions(),
        userStore.updateUserData(),
        this.getPauseInformation(userId)
      ])
    },
    async resumeSubscription({ userId }) {
      await $fetchApi(
        `orders/users/${userId}/autorenewal/membershipPauseHistories`,
        {
          method: 'PUT',
          body: {
            userId,
            operation: 'resume'
          }
        }
      )
      const userStore = useUserStore()
      await Promise.all([
        this.getCurrentSubscription(),
        userStore.updateUserData(),
        this.getPauseInformation(userId)
      ])
    },
    async getUpdatePaymentMethodSession() {
      this.paymentSession = null
      const { protocol, host } = window.location

      const response = await $fetchApi('/v4/payment/paymentMethodSession', {
        method: 'POST',
        body: {
          origin: `${protocol}//${host}`,
          returnUrl: `${protocol}//${host}/account`
        }
      })

      this.paymentSession = response
    },
    setValidatingPayment(value) {
      this.validatingPayment = value
    },
    clearAccountCredentials() {
      this.$state = getInitialState()
    }
  }
})
